/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:d2a1460f-a477-4607-a6c1-f5306ae1ccf5",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_7p0gAJXiy",
    "aws_user_pools_web_client_id": "4jt5j8ah1orb53eqcohe7gkp94",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://c35ftas4lbffpanpgc7qm5e534.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS"
};


export default awsmobile;
